<template>
  <!--  <div>-->
  <!--    <b-button v-b-modal.modal-1>Create User</b-button>-->

  <!--    <b-modal id="modal-1" title="Create Attendee" @ok="create_user">-->
  <!--      <div class="m-3">-->
  <!--        <div class="row m-1">-->
  <!--          <label for="first_name" class="col">First Name</label>-->
  <!--          <input v-model="first_name" type="text" />-->
  <!--        </div>-->
  <!--        <div class="row m-1">-->
  <!--          <label for="last_name" class="col">Last Name</label>-->
  <!--          <input v-model="last_name" type="text" />-->
  <!--        </div>-->
  <!--        <div class="row m-1">-->
  <!--          <label for="attendee_id" class="col">Attendee ID</label>-->
  <!--          <input v-model="event_attendee_id" type="text" />-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </b-modal>-->
  <!--  </div>-->
  <button></button>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "create"
    }
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      event_attendee_id: "",
      email: "",
      additional_data: {}
    };
  },
  methods: {
    create_user() {
      let user_data = {
        email: this.event_attendee_id + "@nmrgames.com",
        password: this.event_attendee_id,
        event_attendee_id: this.event_attendee_id,
        first_name: this.first_name,
        last_name: this.last_name,
        username: this.first_name + " " + this.last_name,
        additional_data: this.additional_data
      };
      console.log(
        "Send data to create user cloud function via dispatch :",
        user_data
      );
      this.$store.dispatch("create_user", user_data).then(status => {
        console.log(status);
        this.$emit("refresh_users");
      });
    }
  }
};
</script>

<style></style>
